import React from 'react';
import logo from './logo.svg';
import './App.css';
import '@fontsource/source-code-pro'
import 'tailwind-typewriter'
import { Helmet } from 'react-helmet'

const hStyle = { color: 'red' };


function App() {
  return (
    <div className="App">
      <Helmet>
          <title>QD© — qdventures</title>
      </Helmet>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
        Building the <span style={{color:'#FF79A9'}}>future</span>
        </h1>
       
       <p>
          We are excited to <span style={{color:'#59FF91'}}>launch the new web-site</span> and introduce our  <span style={{color:'#59FF91'}}>unique brand </span>, <span style={{color:'#A873FF'}}>stay tuned</span> and let's build the future together!
        
        </p>
       
      </header>
    </div>
  );
}

export default App;
